import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Modal } from 'antd';
import { CreateConsumerTokenUsingFbLogin } from './Operations.graphql';
import FacebookLoginButton from '~/components/FacebookLoginButton';
import LoadingDots from '~/components/LoadingDots';

const GuestLoginUsingFb = memo(props => {
    const { isDisabled, onSuccess, role, type, agencyName, agencySuburb, agencyId, locationId } = props;

    const disabledFb = isDisabled ? 'pointer-events-none' : '';

    const [isVisibleSuccess, setIsVisibleSuccess] = useState(false);
    const [isVisibleError, setIsVisibleError] = useState(false);
    const [currentProfile, setCurrentProfile] = useState(null);

    const [createConsumerTokenUsingFb] = useMutation(CreateConsumerTokenUsingFbLogin);

    const responseFacebook = async response => {
        const { profile } = response || {};
        const { email, name, picture, id } = profile;

        if (!email) {
            setIsVisibleError(true);
            return;
        }

        try {
            setIsVisibleSuccess(true);
            setCurrentProfile(profile);

            const { data } = await createConsumerTokenUsingFb({
                variables: {
                    email,
                    name,
                    fbProfileUrl: picture.data.url,
                    id,
                    role,
                    type,
                    agencyName,
                    agencySuburb,
                    agencyId,
                    locationId,
                },
            });

            const { createConsumerTokenUsingFbLogin } = data;

            onSuccess(createConsumerTokenUsingFbLogin).then(() => {
                setIsVisibleSuccess(false);
            });
        } catch (error) {
            setIsVisibleSuccess(false);
            console.log(error);
        }
    };

    return (
        <div className={`GuestLoginUsingFb ${disabledFb}`}>
            <Modal centered visible={isVisibleError} footer={null} closable={false} zIndex={1061}>
                <div className="text-center">
                    <h2>Your facebook account is not associated with an email address</h2>
                    <Button className="m-auto" type="primary" onClick={() => setIsVisibleError(false)}>
                        Okay
                    </Button>
                </div>
            </Modal>
            <Modal centered visible={isVisibleSuccess} footer={null} closable={false} zIndex={1061} width={300}>
                <div className="flex items-center justify-center flex-col items-center">
                    <div className="text-center">
                        <h1>Logging in as</h1>
                        <div className="p-2 flex items-center bg-gray-300 rounded my-3">
                            <Avatar
                                size={50}
                                icon={<UserOutlined />}
                                src={currentProfile?.picture?.data?.url || ''}
                                className="mx-3"
                            />
                            <h4>{currentProfile?.name || ''}</h4>
                        </div>
                    </div>
                    <LoadingDots />
                </div>
            </Modal>
            <FacebookLoginButton className="mx-3" onSuccess={responseFacebook} />
        </div>
    );
});

GuestLoginUsingFb.propTypes = {
    onSuccess: PropTypes.func,
    isDisabled: PropTypes.bool,
    type: PropTypes.string,
    role: PropTypes.string,
    agencyName: PropTypes.string,
    agencySuburb: PropTypes.string,
    agencyId: PropTypes.string,
    locationId: PropTypes.string,
};

GuestLoginUsingFb.defaultProps = {
    onSuccess: () => {},
    isDisabled: false,
    type: null,
    role: null,
    agencyName: null,
    agencySuburb: null,
    agencyId: null,
    locationId: null,
};

export default GuestLoginUsingFb;
