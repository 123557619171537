import React from 'react';
import PropTypes from 'prop-types';
import { LoginButton, FacebookProvider } from 'react-facebook';
import clsx from 'clsx';
import fbBtnImg from './fb-button-img.png';
import styles from './FacebookLoginButton.module.scss';

/* onSuccess returns
{
  "profile": {
    "id": "",
    "first_name": "Jessie Cris",
    "last_name": "Vicerra",
    "name": "Jessie Cris Vicerra",
    "name_format": "{first} {last}",
    "picture": {},
    "short_name": "Jessie Cris",
    "email": ""
  },
  "tokenDetail": {
    "accessToken": "",
    "userID": "",
    "expiresIn": 7031,
    "signedRequest": "",
    "data_access_expiration_time": 1587279769
  }
}


*/
const FacebookLoginButton = ({ onSuccess, className }) => {
    const handleResponse = (data) => {
        onSuccess(data);
    };

    const handleError = () => {};
    return (
        <FacebookProvider appId={process.env.NEXT_PUBLIC_FB_APP_ID}>
            <LoginButton
                scope="email"
                onCompleted={handleResponse}
                onError={handleError}
                className={clsx('mb-4 sm:mb-0', styles.FacebookLoginButton, className)}
            >
                <img src={fbBtnImg} alt="Facebook" />
            </LoginButton>
        </FacebookProvider>
    );
};

FacebookLoginButton.propTypes = { onSuccess: PropTypes.func, className: PropTypes.string };

FacebookLoginButton.defaultProps = { onSuccess: () => {}, className: '' };

export default FacebookLoginButton;
