import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Alert, Button, Input, Form } from 'antd';
import GuestLoginUsingFb from '~/components/GuestLoginUsingFb';
import GuestLoginUsingGoogle from '~/components/GuestLoginUsingGoogle';
import { CreateConsumerTokenMutation } from '../../queries/CreateConsumerTokenMutation.graphql';
import agencyImg from './agency.png';
import agentImg from './agent.png';
import brokerImg from './broker.png';
import memberImg from './member.png';
import styles from './GuestLoginForm.module.scss';

const GuestLoginForm = ({ isPage, onSuccess, onSuccessAccount }) => {
    const [createConsumerToken, { loading }] = useMutation(CreateConsumerTokenMutation);
    const [formErrorCode, setFormErrorCode] = useState(null);

    const [form] = Form.useForm();
    const getErrorMessage = () => {
        let message = null;

        switch (formErrorCode) {
            case 'CONSUMER_NOT_EXIST': {
                message = 'It seems that your email is not yet registered';
                break;
            }
            case 'PASSWORD_MISMATCH': {
                message = 'It seems that your password is incorrect';
                break;
            }
            case 'CONSUMER_NOT_ACTIVE': {
                message = 'It seems that the account you provided is inactive';
                break;
            }
            case 'CONSUMER_NOT_VERIFIED': {
                message = 'It seems that the account you provided is not verified';
                break;
            }
            default: {
                message = 'Unable to login';
                break;
            }
        }

        return message;
    };

    const loginConsumer = async (values) => {
        // e.preventDefault();

        setFormErrorCode(null);

        const { email, password } = values;

        try {
            const {
                data: { createConsumerToken: createConsumerTokenData },
            } = await createConsumerToken({
                variables: {
                    username: email.trim(),
                    password,
                },
            });
            onSuccess(createConsumerTokenData);
        } catch (error) {
            const errors = error.graphQLErrors || [];
            const formErrCode = errors.length > 0 ? errors[0].extensions.code : null;

            setFormErrorCode(formErrCode);
        }
    };

    return (
        <div className={`${styles.GuestLoginForm} my-4 rounded border p-6 shadow`}>
            {isPage ? (
                <section className="text-center font-medium">
                    <h1 className="text-2xl sm:mb-10">Log In</h1>
                    <p className="mb-4 text-base">Choose Account Type</p>
                    <div className={`${styles.primaryAccountTypeBox} flex flex-wrap items-center justify-between`}>
                        <div className={`${styles.accountTypeBox} ${styles.accountTypeBoxActive}`}>
                            <div className={styles.accountTypeImage}>
                                <img src={memberImg} alt="Member" />
                            </div>
                            <div className={`${styles.accountTypeLabel} text-center text-sm`}>Member</div>
                        </div>
                        <div className={styles.accountTypeBox}>
                            <a
                                className="block"
                                href="https://agent.realty.com.au"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div className={styles.accountTypeImage}>
                                    <img src={agentImg} alt="Agent" />
                                </div>
                                <div className={`${styles.accountTypeLabel} text-center text-sm`}>Agent</div>
                            </a>
                        </div>
                        <div className={styles.accountTypeBox}>
                            <a
                                className="block"
                                href="https://agency.realty.com.au"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div className={styles.accountTypeImage}>
                                    <img src={agencyImg} alt="Agency" />
                                </div>
                                <div className={`${styles.accountTypeLabel} text-center text-sm`}>Agency</div>
                            </a>
                        </div>
                        <div className={styles.accountTypeBox}>
                            <a
                                className="block"
                                href="https://broker.realty.com.au"
                                target="_blank"
                                title={'Realty Broker Admin'}
                                rel="noopener noreferrer"
                            >
                                <div className={styles.accountTypeImage}>
                                    <img src={brokerImg} alt="Broker" />
                                </div>
                                <div className={`${styles.accountTypeLabel} text-center text-sm`}>Broker</div>
                            </a>
                        </div>
                    </div>
                    <div className={`${styles.secondaryAccountTypeBox} flex flex-wrap items-center justify-center`}>
                        <div>
                            <div className={`${styles.accountTypeBox} ${styles.accountTypeBoxActive}`}>
                                <div className={styles.accountTypeImage}>
                                    <img src={memberImg} alt="Member" />
                                </div>
                                <div className={`${styles.accountTypeLabel} text-center text-sm`}>Member</div>
                            </div>
                            <div className={styles.accountTypeBox}>
                                <a
                                    className="block"
                                    href="https://agent.realty.com.au"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <div className={styles.accountTypeImage}>
                                        <img src={agentImg} alt="Agent" />
                                    </div>
                                    <div className={`${styles.accountTypeLabel} text-center text-sm`}>Agent</div>
                                </a>
                            </div>
                        </div>
                        <div>
                            <div className={styles.accountTypeBox}>
                                <a
                                    className="block"
                                    href="https://agency.realty.com.au"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <div className={styles.accountTypeImage}>
                                        <img src={agencyImg} alt="Agency" />
                                    </div>
                                    <div className={`${styles.accountTypeLabel} text-center text-sm`}>Agency</div>
                                </a>
                            </div>
                            <div className={styles.accountTypeBox}>
                                <a
                                    className="block"
                                    href="https://broker.realty.com.au"
                                    target="_blank"
                                    title={'Realty Broker Admin'}
                                    rel="noopener noreferrer"
                                >
                                    <div className={styles.accountTypeImage}>
                                        <img src={brokerImg} alt="Broker" />
                                    </div>
                                    <div className={`${styles.accountTypeLabel} text-center text-sm`}>Broker</div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={styles.accountTypeDivider} />
                </section>
            ) : (
                <section>
                    <h2 className="mb-2 mb-6 text-center text-lg uppercase text-gray-800">Sign in to your account</h2>
                </section>
            )}
            <Form onFinish={loginConsumer} className={styles.guestLoginForm} form={form}>
                <span className="ml-4 inline-block py-1 text-xs font-medium">Email</span>
                <Form.Item
                    name="email"
                    rules={[
                        { transform: (value) => (value ? value.trim() : value) },
                        {
                            type: 'email',
                            message: 'Invalid email!',
                        },
                        {
                            required: true,
                            message: 'Email is required!',
                        },
                    ]}
                >
                    <Input size="large" allowClear prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} />
                </Form.Item>
                <span className="ml-4 inline-block py-1 text-xs font-medium">Password</span>
                <Form.Item
                    className={styles.passwordFormItem}
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Password is required!',
                        },
                    ]}
                >
                    <Input.Password
                        size="large"
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="password"
                    />
                </Form.Item>
                <div className={`${styles.additionalOptions} flex items-center justify-end`}>
                    <div>
                        <Link href="/forgot-password">
                            <a>Forgot Password?</a>
                        </Link>
                    </div>
                </div>
                <Form.Item className={`${styles.buttonFormItem} pt-8`}>
                    <Button
                        className="w-full text-base font-medium"
                        type="primary"
                        size="large"
                        htmlType="submit"
                        loading={loading}
                    >
                        {loading ? 'Logging in' : 'Login'}
                    </Button>
                    <div className={`${formErrorCode ? 'h-16' : 'h-6'} py-2 transition`}>
                        {formErrorCode && <Alert message={getErrorMessage()} type="error" showIcon />}
                    </div>
                </Form.Item>
            </Form>
            <div className="mb-10 justify-center text-center sm:flex">
                <GuestLoginUsingFb onSuccess={onSuccessAccount} />
                <GuestLoginUsingGoogle onSuccess={onSuccessAccount} />
            </div>
            <div className={`${styles.signUpOption} text-center text-sm`}>
                Don&apos;t have an account?{' '}
                <Link href="/sign-up">
                    <a className="underline">SIGN UP!</a>
                </Link>
            </div>
        </div>
    );
};

GuestLoginForm.propTypes = {
    isPage: PropTypes.bool,
    onSuccess: PropTypes.func,
    onSuccessAccount: PropTypes.oneOfType([PropTypes.instanceOf(Promise), PropTypes.func]),
};

GuestLoginForm.defaultProps = {
    isPage: false,
    onSuccess: () => {},
    onSuccessAccount: () => Promise.resolve(),
};

export default GuestLoginForm;
