/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import GoogleLogin from 'react-google-login';
import clsx from 'clsx';
import googleBtnImg from './google-button-img.png';
import styles from './GoogleLoginButton.module.scss';

/* onSuccess returns
{
  "El": "", "Zi": {
  "token_type": "Bearer",
  "access_token": "ya29.",
  "scope": "email profile openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
  "login_hint": "",
  "expires_in": 3599,
  "id_token": "",
  "session_state": {"extraQueryParams": {"authuser": "0"}}, "first_issued_at": 1579504747298,
  "expires_at": 1579508346298, "idpId": "google"
}, "w3": {
  "Eea": "", "ig": "Jessie Cris Vicerra", "ofa": "Jessie Cris", "wea": "Vicerra",
  "Paa": "",
  "U3": ""
}, "googleId": "", "tokenObj": {
  "token_type": "Bearer",
  "access_token": "ya29.",
  "scope": "email profile openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
  "login_hint": "",
  "expires_in": 3599,
  "id_token": "",
  "session_state": {"extraQueryParams": {"authuser": "0"}}, "first_issued_at": 1579504747298,
  "expires_at": 1579508346298, "idpId": "google"
},
  "tokenId": "",
  "accessToken": "ya29.Ime...",
  "profileObj": {
    "googleId": "",
    "imageUrl": "",
    "email": "", "name": "Jessie Cris Vicerra", "givenName": "Jessie Cris", "familyName": "Vicerra"
  }
}
 */
const GoogleLoginButton = ({ onSuccess, className }) => {
    const handleResponse = (data) => {
        onSuccess(data);
    };

    const handleError = () => {};

    return (
        <GoogleLogin
            clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
            icon={false}
            onSuccess={handleResponse}
            onFailure={handleError}
            tag="div"
            type={null}
            render={(renderProps) => (
                <button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className={clsx(styles.GoogleLoginButton, className)}
                >
                    <img src={googleBtnImg} alt="Google" />
                </button>
            )}
        />
    );
};

GoogleLoginButton.propTypes = { onSuccess: PropTypes.func, className: PropTypes.string };

GoogleLoginButton.defaultProps = { onSuccess: () => {}, className: '' };

export default GoogleLoginButton;
