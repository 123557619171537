import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Modal } from 'antd';
import { useMutation } from '@apollo/client';
import CreateConsumerTokenUsingGoogleLogin from './Operations.graphql';
import GoogleLoginButton from '~/components/GoogleLoginButton';
import LoadingDots from '~/components/LoadingDots';

const GuestLoginUsingGoogle = memo((props) => {
    const { isDisabled, onSuccess, type, role, agencyName, agencySuburb, agencyId, locationId } = props;

    const disabledGoogle = isDisabled ? 'pointer-events-none' : '';

    const [isVisibleSuccess, setIsVisibleSuccess] = useState(false);
    const [currentProfile, setCurrentProfile] = useState(null);

    const [createConsumerTokenUsingGoogle] = useMutation(CreateConsumerTokenUsingGoogleLogin);

    const responseGoogle = async (response) => {
        const {
            profileObj,
            profileObj: { givenName, familyName, email, googleId, imageUrl },
        } = response;

        try {
            setIsVisibleSuccess(true);
            setCurrentProfile(profileObj);

            const { data } = await createConsumerTokenUsingGoogle({
                variables: {
                    email,
                    firstName: givenName,
                    lastName: familyName,
                    googleProfileUrl: imageUrl,
                    googleId,
                    type,
                    role,
                    agencyName,
                    agencySuburb,
                    agencyId,
                    locationId,
                },
            });

            const { createConsumerTokenUsingGoogleLogin } = data;

            onSuccess(createConsumerTokenUsingGoogleLogin).then(() => {
                setIsVisibleSuccess(false);
            });
        } catch (error) {
            setIsVisibleSuccess(false);
            console.log(error);
        }
    };
    return (
        <div className={`GuestLoginUsingGoogle ${disabledGoogle}`}>
            <GoogleLoginButton className="mx-3" onSuccess={responseGoogle} />
            <Modal centered visible={isVisibleSuccess} footer={null} closable={false} zIndex={1061} width={300}>
                <div className="flex flex-col items-center items-center justify-center">
                    <div className="text-center">
                        <h1>Logging in as</h1>
                        <div className="my-3 flex items-center rounded bg-gray-300 p-2">
                            <Avatar
                                size={50}
                                icon={<UserOutlined />}
                                src={currentProfile?.imageUrl || ''}
                                className="mx-3"
                            />
                            <h4>{currentProfile?.givenName || ''}</h4>
                        </div>
                    </div>
                    <LoadingDots />
                </div>
            </Modal>
        </div>
    );
});

GuestLoginUsingGoogle.propTypes = {
    onSuccess: PropTypes.func,
    isDisabled: PropTypes.bool,
    type: PropTypes.string,
    role: PropTypes.string,
    agencyName: PropTypes.string,
    agencySuburb: PropTypes.string,
    agencyId: PropTypes.string,
    locationId: PropTypes.string,
};

GuestLoginUsingGoogle.defaultProps = {
    onSuccess: () => {},
    isDisabled: false,
    type: null,
    role: null,
    agencyName: null,
    agencySuburb: null,
    agencyId: null,
    locationId: null,
};

export default GuestLoginUsingGoogle;
